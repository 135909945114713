import React, { Component } from "react";
import PropTypes from "prop-types";
import { CustomInputTime } from "./style";

export default class CommonInputTime extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <CustomInputTime
        {...this.props}
      />
    );
  }
}

CommonInputTime.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  type: PropTypes.any,
  maxLength: PropTypes.number,
  pattern : PropTypes.any,
  min : PropTypes.any,
  onKeyPress : PropTypes.func,
  step : PropTypes.any,
  disabled : PropTypes.any

};