import styled from 'styled-components';


const CustomInput = styled.input`
  font-size: 16px;
  padding: 12px 10px;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  float: right;
  width: 100%;
  padding-right: 25%;
  &:hover, &:active, &:focus {
    outline: none;
  }
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cdcbcb;
  height: 50px;
  color: rgb(153,153,153);
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export { CustomInput };