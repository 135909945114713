import styled from 'styled-components';


const CustomInputTime = styled.input`
  font-size: 20px;
  padding: 12px 10px;
  border-radius: ${({border}) => border ? '0px 4px 4px 0px' : '4px 0 0 4px'};
  border: 1px solid #cdcbcb;
  border-right: ${({border}) => border ? '1px solid #cdcbcb' : 'none'};
  border-left: ${({border}) => border ? 'none' : '1px solid #cdcbcb' };
  float: left;
  width: 90px;
  position: relative;
  height: 46px;
  text-align: center;
  color: rgb(153,153,153);
  &:hover, &:active, &:focus {
    outline: none;
  }
  @media(max-width: 500px){
    width: 20%;
  }
`;

export { CustomInputTime };