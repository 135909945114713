import styled from 'styled-components';


const CustomTextArea = styled.textarea`
  font-size: 14px;
  padding: 18px 15px;
  border-radius: ${({ style }) => style && style.borderRadius ? style.borderRadius : '4px'};
  border: 1px solid #cdcbcb;
  float: ${({ style }) => style && style.float ? style.float : 'right'};
  width: ${({ style }) => style && style.width ? style.width :  '100%' };
  height:${({ style }) => style && style.height ? style.height :  '' } ;
  background-color: ${({ style }) => style && style.backgroundColor ? style.backgroundColor :  'white' } ;
  margin-top: 16px;
  resize: none;
  color:${({ style }) => style && style.color ? style.color : 'rgb(153,153,153)'};
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: ${({ style }) => style && style.placeholderColor ? style.placeholderColor :  `rgb(153,153,153)`};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

export { CustomTextArea };