import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ToolTip } from '../AdminTeamCreation/styles';
import PropTypes from "prop-types";

const HoverButton = ({title, onClick, svgPath, width, height, backgroundColor, backgroundColorActive, marginLeft}) => {
  const[isBtnActive, setIsBtnActive] = useState(false);


  useEffect(()=>{window.console.log("hi"); return ()=>{setIsBtnActive(false)}},[])
  const popover = () => (
    <Popover
      id="popover-basic"
      style={{width: "auto",padding: "10px 10px",background: "#FFFFFF",border: "1px solid #0D4270", boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",borderRadius: "6px",zIndex:"1051", "&:.arrow": { border: "1px solid black" }}} >
      <div
        style={{fontFamily: "Rubik",fontStyle: "normal", fontWeight: "400",fontSize: "14px",lineHeight: "16px",color: "#0D4270",textAlign: "center",zIndex:"1051"}}>
        {title}
      </div>
    </Popover>);
  return (
    <ToolTip
      active={isBtnActive?backgroundColor : backgroundColorActive}
      onMouseEnter={() =>setIsBtnActive(true)}
      onMouseLeave={() =>setIsBtnActive(false)}
      style={{ margin: "0px", width: "auto", height: "100%",zIndex:"400" }}
      fill={ !isBtnActive ? backgroundColorActive : "#fff"}
      activeOpacity={!isBtnActive}
    >
      <OverlayTrigger placement="bottom" overlay={popover()}>
        <div
          className='="toolTip'
          style={{
            cursor: "pointer",
            margin: marginLeft?marginLeft:"0px 0px 0px 15px",
            background: !isBtnActive ? backgroundColor : backgroundColorActive,zIndex:"400"
          }}
          onClick={onClick}
        >

          <svg
            width={width || '24'}
            height={height || '24'}
            viewBox={`0 0 ${width || 24} ${height || 24}`}
            fill={ !isBtnActive ? backgroundColorActive : "#fff"}
            xmlns="http://www.w3.org/2000/svg"
          >
            {svgPath}
          </svg>
        </div>
      </OverlayTrigger>
    </ToolTip>
  )
}

HoverButton.propTypes = {
  title:PropTypes.string,
  onClick:PropTypes.func,
  svgPath:PropTypes.element,
  width:PropTypes.string,
  height:PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorActive: PropTypes.string,
  marginLeft: PropTypes.string
}

export default HoverButton