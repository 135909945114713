import React, { useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ToolTip } from '../AdminTeamCreation/styles';
import PropTypes from "prop-types";

const HoverButton = ({title, onClick, svgPath, width, height,fillOpacity,disabled, backgroundColor, backgroundColorActive, activeColor, inActiveColor, marginLeft}) => {
  const[isBtnActive, setIsBtnActive] = useState(false);
  const popover = () => (
    <Popover
      id="popover-basic"
      style={{width: "auto",padding: "9px 14px",background: "#FFFFFF",border: "1px solid #0D4270", boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",borderRadius: "6px",}} >
      <div
        style={{fontFamily: "Rubik",fontStyle: "normal", fontWeight: "400",fontSize: "14px",lineHeight: "16px",color: "#0D4270",textAlign: "center",}}>
        {title}
      </div>
    </Popover>);
  return (
    <ToolTip
      active={isBtnActive}
      style={{ margin: "0px", width: "auto", height: "100%", alignItems:'center' }}
      fill={ !isBtnActive ? (disabled ? activeColor ? activeColor: "#fff" :  activeColor ? activeColor: "#007AB1") : "#fff"}
      fillOpacity={ !isBtnActive && "0.6"}
    >
      <OverlayTrigger placement="bottom" overlay={ popover()}>
        <div
          className='="toolTip'
          style={{
            cursor: "pointer",
            marginLeft: marginLeft ? marginLeft : "10px",
            background: !isBtnActive ? (disabled ? 'rgba(0, 92, 135, 0.1)' : backgroundColor ? backgroundColor : 'rgba(0, 92, 135, 0.05)') : backgroundColorActive ? backgroundColorActive : "rgba(0, 92, 135, 1)",
          // opacity: !coreValueBtn && "0.1",
          }}
          onClick={onClick}
          onMouseEnter={() =>!disabled && setIsBtnActive(!isBtnActive)}
          onMouseLeave={() =>!disabled && setIsBtnActive(!isBtnActive)}
        >

          <svg
            width={width || '24'}
            height={height || '24'}
            viewBox={`0 0 ${width || 24} ${height || 24}`}
            fill={ !isBtnActive ? activeColor ? activeColor : "#005C87" : inActiveColor ? inActiveColor : "#fff"}
            xmlns="http://www.w3.org/2000/svg"
            fillOpacity={ !isBtnActive && (fillOpacity ? fillOpacity : disabled ? "1" :"0.6") }
          >
            {svgPath}
          </svg>
        </div>
      </OverlayTrigger>
    </ToolTip>
  )
}

HoverButton.propTypes = {
  title:PropTypes.string,
  onClick:PropTypes.func,
  svgPath:PropTypes.element,
  width:PropTypes.string,
  height:PropTypes.string,
  activeColorBackground: PropTypes.string,
  fillOpacity:PropTypes.string,
  disabled:PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundColorActive: PropTypes.string,
  activeColor: PropTypes.string,
  inActiveColor: PropTypes.string,
  marginLeft: PropTypes.string,

}

export default HoverButton