import React from "react";
import PropTypes from "prop-types";
import { UploadPhotoContainer, RemoveImageV2, InputImage } from "./style";
import { closeIcon, UploadIcon } from "../../../utils/icons";
import { getOrientation, resetOrientation, checkImage } from "../../../utils/methods";
import { imageErrorMessage } from "../../../utils/constants";
import { toast } from "react-toastify";


class CommonImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: props.imgSrc || null,
      imageName: "",
    };
    this.fileInputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imgSrc !== this.props.imgSrc) {
      this.setState({ imgSrc: this.props.imgSrc });
    }
  }

  componentWillUnmount() {
    this.setState({ imgSrc: null });
  }
  onChange = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
  
        // Get the file name directly from the file object
        const imageName = file.name;
  
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            imageName: imageName,
            editImage: reader.result,
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: baseImage,
                editImage: baseImage,
              });
              if (this.props.onImageChange) {
                this.props.onImageChange(baseImage, imageName, baseImage);
              }
            });
          });
        };
      } else {
        toast.error('Please select an image file less than 20MB');
        this.fileInputRef.current.value = ''; // Clear the input value
      }
    } else {
      toast.error(imageErrorMessage);
      this.fileInputRef.current.value = ''; // Clear the input value
    }
  };
  




  // onChange = (e) => {
  //   let file = e.target.files[0];
  //   if (checkImage(e.target.files)) {
  //     if ((file?.size / 1000000) <= 20) {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       const array = document.getElementById('create-challenge-file').value.split("\\");
  //       reader.onloadend = () => {
  //         this.setState({
  //           imgSrc: reader.result,
  //           imageName: array[array.length - 1],
  //           editImage: reader.result
  //         });
  //         getOrientation(file, (or) => {
  //           resetOrientation([reader.result], or, (baseImage) => {
  //             this.setState({
  //               imgSrc: baseImage,
  //               editImage: baseImage,
  //               // imageUpdated: this.props.editLibrary ? 1 : 0
  //             });
  //           });
  //         });
  //       };
  //     } else {
  //       toast.error('Please select image file less than 20MB');
  //       document.getElementById('create-challenge-file').value = '';
  //     }
  //   } else {
  //     toast.error(imageErrorMessage);
  //     document.getElementById('create-challenge-file').value = '';
  //   }
  // };


  // onChange = (e) => {
  //   window.console.log("after changing",e)
  //   let file = e.target.files[0];
  //   let fileArr = e.target.files;
  //   if (file) {
  //     if (checkImage(fileArr)) {
  //       if (file.size / 1000000 <= 20) {
  //         let reader = new FileReader();
  //         reader.readAsDataURL(file);
  //         const array = document
  //           .getElementById("upload-file")
  //           .value.split("\\");
  //         reader.onloadend = () => {
  //           this.setState({
  //             imgSrc: reader.result,
  //             imageName: array[array.length - 1],
  //           });
  //           getOrientation(file, (or) => {
  //             resetOrientation([reader.result], or, (baseImage) => {
  //               this.setState({
  //                 imgSrc: baseImage,
  //               });
  //               if (this.props.onImageChange) {
  //                 this.props.onImageChange(baseImage, array[array.length - 1]);
  //               }
  //             });
  //           });
  //         };
  //       } else {
  //         toast.error("Please select an image file less than 20MB");
  //         document.getElementById("event-upload-file").value = "";
  //       }
  //     } else {
  //       toast.error(imageErrorMessage);
  //       document.getElementById("event-upload-file").value = "";
  //     }
  //   }
  // };

  removePhoto = () => {
    this.setState({ imgSrc: null, imageName: "" });
    if (this.props.onRemoveImage) {
      this.props.onRemoveImage();
    }
  };

  render() {
    const { imgSrc } = this.state;
    const { t } = this.props;
    window.console.log("imgsrc is",imgSrc);
    return (
      <UploadPhotoContainer>
        <div className="innerDivImageContainer">
          {imgSrc ? (
            <RemoveImageV2>
              <img alt="profile-img" src={imgSrc}></img>
              <div className="main-div" onClick={this.removePhoto}>
                <div className="icon-div">
                  <div className="sub-icon-div">{closeIcon("white", "8", "8")}</div>
                </div>
              </div>
            </RemoveImageV2>
          ) : (
            <div className="uploadImage">
              <InputImage
                className="edit-profile-pic"
                imageReload={1}
                style={{ background: "unset", color: "#005c87" }}
              >
                <div className="middle-section" onClick={() => this.fileInputRef.current.click()}>
                  <div>{UploadIcon()}</div>
                  <div className="title">{t("Upload File")}</div>
                  <div className="sub-title">{t("(.jpeg, .png)")}</div>
                </div>
                <input
                  className="input-section"
                  id="upload-file"
                  type="file"
                  name="user"
                  accept=".jpeg, .png, .jpg"
                  multiple={false}
                  onChange={(e) => this.onChange(e)}
                  onClick={(e) =>e.target.files[0] && this.onChange(e)}
                  ref={this.fileInputRef}
                />
              </InputImage>
            </div>
          )}
        </div>
      </UploadPhotoContainer>
    );
  }
}

CommonImageUploader.propTypes = {
  imgSrc: PropTypes.string,
  t: PropTypes.func.isRequired,
  onImageChange: PropTypes.func,
  onRemoveImage: PropTypes.func,
};

CommonImageUploader.defaultProps = {
  imgSrc: null,
  onImageChange: () => {},
  onRemoveImage: () => {},
};

export default CommonImageUploader;
